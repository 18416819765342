import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

//* simple - just carry a value or even carry nothing
export const addSomeValue = createAction<number>('addSome');

//* with function - get value and make
export const addDoubleValue = createAction('addDouble', (n: number) => ({ payload: 2 * n }));

//* async - take effect after resolve all promises
export const asyncValue = createAsyncThunk(
	'addAsync',
	async (value: number, { rejectWithValue }) => {
		try {
			// this can replace with any async functions like fetch
			await new Promise((resolve, reject) => setTimeout(resolve, 5000));
			return { value: value };
		} catch {
			return rejectWithValue({ error: 'some problem happened' });
		}
	}
);
