// <reference types="redux-persist" />
import { FC } from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
// import { PersistGate } from 'redux-persist/integration/react';
// import persistStore from 'redux-persist/es/persistStore';

import reducers from './reducers';

export const store = configureStore({
	reducer: reducers,
	// devTools: true,
});

const Store: FC = ({ children }) => (
	<Provider store={store}>
		{/* <PersistGate persistor={persistStore(store)}> */}
		{children}
		{/* </PersistGate> */}
	</Provider>
);

export type StoreTypes = ReturnType<typeof store.getState>;

export default Store;
