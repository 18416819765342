import { combineReducers } from 'redux';

// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import { CONFIG } from '../../../App/Config/constants';
import counterReducer from './CounterReducer';

const reducers = combineReducers({
	counter: counterReducer,
	// account: persistReducer(
	// 	{
	// 		key: 'account',
	// 		storage,
	// 		keyPrefix: `${CONFIG.APP_NAME}-`,
	// 	},
	// 	accountReducer
	// ),
});

export default reducers;
