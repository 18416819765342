import { createReducer } from '@reduxjs/toolkit';
import { addSomeValue, addDoubleValue, asyncValue } from '../actions/CounterActions';

const initState = { value: 0, err: '' };

const counterReducer = createReducer(initState, {
	[addSomeValue.type]: (state, { payload }) => ({
		...state,
		value: state.value + payload,
	}),

	[addDoubleValue.type]: (state, { payload }) => ({
		...state,
		value: state.value + payload,
	}),

	[asyncValue.rejected.type]: (state, { payload }) => ({
		value: state.value,
		err: payload.error as string,
	}),
	[asyncValue.pending.type]: (state, _) => ({
		...state,
		value: 'loading...',
	}),
	[asyncValue.fulfilled.type]: (state, { payload }) => ({
		...state,
		value: payload,
	}),
});

export default counterReducer;
