import { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import { Path } from './RouteRenderer';
import { CONFIG } from '../Config/constants';

export const routesArray: Path[] = [
    {
        exact: true,
        path: ["/", "/home"],
        component: lazy(() => import("../../Views/Home/Home")),
    },
    {
        exact: true,
        path: ["/cv"],
        component: lazy(() => import("../../Views/CV/CV")),
    },
    {
        exact: true,
        path: ["/cp"],
        component: lazy(() => import("../../Views/CP/CP")),
    },
    {
        exact: true,
        path: ["/dev"],
        component: lazy(() => import("../../Views/Dev/Dev")),
    },
    {
        path: ["*"],
        exact: true,
        component: () => <Redirect to={CONFIG.BASE_URL} />,
    },
];

export default routesArray;
