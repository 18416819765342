import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Store from './Tools/Store';
import "./Assets/scss/index.scss";
import App from './App/App';



const app = (
	<BrowserRouter>
		<Store>
			<App />
		</Store>
	</BrowserRouter>
);

ReactDOM.render(app, document.querySelector('#root'));
reportWebVitals();
